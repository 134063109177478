import { Box, Text } from 'grommet';
import { useCallback, useEffect, useState, lazy } from 'react';

import { useLocation } from '@reach/router';

//const Assistant = lazy(() => import(/* webpackChunkName: "Sidebars" */ '../sidebars/assistant/assistant'));
import Assistant from '../sidebars/assistant/assistant';
import config from '../config';
import { usePlatformQuery, useSessionStore } from '../hooks';

const opts = config.defaultFetchOpts;

const EmbedAssistant = ({ id }) => {
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const shadow = params.get('shadow') !== 'disable';

  return (
    <Box margin="xsmall">
      <Assistant iframe={true} />
    </Box>
  );
};

const EmbedSession = () => {
  const [id, setId] = useState(null);

  const location = useLocation();

  const session = useSessionStore((state) => state.session);
  const setSession = useSessionStore((state) => state.setSession);

  const checkAuthentication = useCallback(async () => {
    const params = new URLSearchParams(location.search);
    let token = params.get('token');
    const tokensrc = params.get('tokensrc');
    if (tokensrc) token = tokensrc + ':' + token;
    opts.headers['Authorization'] = `Bearer ${token}`;

    const response = await fetch(`${config.apiUrl}/session/myprofile`, opts);
    const data = await response.json();

    if (!response.ok || !data.Data?.authenticated) {
      /* window.parent.location.href = `${config.hostUrl}/account/login?returnUrl=${encodeURIComponent(window.parent.location.href)}${
        config.tenantId ? `&tid=${config.tenantId}` : ''
      }`; */
      return;
    }

    setId(params.get('id'));
    setSession(data.Data);
  }, [location.search, setSession]);

  useEffect(() => {
    checkAuthentication();
  }, [checkAuthentication]);

  return session.authenticated == true ? <EmbedAssistant id={id} /> : session.authenticated == false ? <Text>Authentication failed</Text> : null;
};

export default EmbedSession;
